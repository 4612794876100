import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorLayout = _resolveComponent("ErrorLayout")!

  return (_openBlock(), _createBlock(_component_ErrorLayout, {
    "is-no-organisation": "",
    title: _ctx.$t('platform.error.no-org-unit.title'),
    message: _ctx.$t('platform.error.no-org-unit.message')
  }, null, 8, ["title", "message"]))
}